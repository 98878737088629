import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import { GET } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import AddVisitDialog from "@/view/pages/job/partials/Add-Visit-Dialog.vue";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "calendar",
  components: {
    DetailTemplate: DetailTemplate,
    Barcode: Barcode,
    Datepicker: Datepicker,
    SingleVisitDetail: SingleVisitDetail,
    AddVisitDialog: AddVisitDialog
  },
  data: function data() {
    return {
      defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
      eventLoading: true,
      jobLoading: true,
      viewVisitDialog: false,
      addVisitDialog: false,
      visitId: 0,
      visitTicketDetail: new Object(),
      visitTicketCustomer: new Object(),
      visitTicketProperty: new Object(),
      visitTicketContactPerson: new Object(),
      ticketDetail: new Object(),
      ticketCustomer: new Object(),
      ticketProperty: new Object(),
      ticketContactPerson: new Object(),
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      events: [],
      unAssignedJobs: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    _this.getCalendarEvents();

    _this.getUnassignedVisits();

    if (_this.$refs["calendar"]) {
      _this.$refs["calendar"].checkChange();
    }

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Calendar"
    }]);

    DialogJobEventBus.$on("load:visit", function () {
      _this.getCalendarEvents();

      _this.getUnassignedVisits();
    });
    DialogJobEventBus.$on("load:job", function () {
      _this.visitId = 0;
      _this.viewVisitDialog = false;
      _this.visitTicketDetail = new Object();
      _this.visitTicketCustomer = new Object();
      _this.visitTicketProperty = new Object();
      _this.visitTicketContactPerson = new Object();
    });
    DialogJobEventBus.$on("close:add-visit-dialog", function () {
      _this.addVisitDialog = false;
    });
  },
  beforeDestroy: function beforeDestroy() {
    /*DialogJobEventBus.$off("close:add-visit-dialog");
    DialogJobEventBus.$off("load:visit");
    DialogJobEventBus.$off("load:job");*/
  },
  methods: {
    getPriorityColor: function getPriorityColor(priority_text) {
      if (priority_text == "Low") {
        return "light-green darken-3";
      }

      if (priority_text == "Medium") {
        return "orange darken-4";
      }

      if (priority_text == "High") {
        return "red darken-3";
      }

      return "cyan";
    },
    setDefaultDate: function setDefaultDate(date) {
      this.defaultDate = moment(date).startOf("day").format("YYYY-MM-DD");

      if (this.$refs["calendar"]) {
        this.$refs["calendar"].checkChange();
      }
    },
    setToday: function setToday() {
      this.focus = "";
      this.defaultDate = moment().startOf("day").format("YYYY-MM-DD");

      if (this.$refs["calendar"]) {
        this.$refs["calendar"].checkChange();
      }
    },
    isToday: function isToday() {
      var defaultDate = moment(this.defaultDate).startOf("day").format("YYYY-MM-DD");

      if (this.$refs["calendar"]) {
        if (this.$refs["calendar"].value) {
          defaultDate = moment(this.$refs["calendar"].value).startOf("day").format("YYYY-MM-DD");
        }
      }

      var today = moment().startOf("day").format("YYYY-MM-DD");
      return today == defaultDate;
    },
    getCalendarEvents: function getCalendarEvents() {
      var _this = this;

      _this.eventLoading = true;

      _this.$store.dispatch(GET, {
        url: "calendar/visits"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.events = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.eventLoading = false;
      });
    },
    getUnassignedVisits: function getUnassignedVisits() {
      var _this = this;

      _this.jobLoading = true;

      _this.$store.dispatch(GET, {
        url: "job/un-assigned"
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.unAssignedJobs = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.jobLoading = false;
      });
    },
    dragStart: function dragStart(row) {
      this.ticketDetail = new Object({
        barcode: row.barcode,
        attention: row.attention,
        title: row.title,
        id: row.ticket_id
      });
      this.ticketCustomer = new Object({
        customer: row.customer
      });
      this.ticketProperty = new Object({
        unit_no: row.unit_no,
        street_1: row.street_1,
        street_2: row.street_2,
        zip_code: row.zip_code
      });
      this.ticketContactPerson = new Object({
        primary_phone: row.customer_primary_phone
      });
    },
    dropEngineer: function dropEngineer(scheduleDate) {
      this.addVisitDialog = true;
      setTimeout(function () {
        JobScheduleTeamEventBus.$emit("update:schedule-date", scheduleDate);
      }, 400);
    },
    allowDrop: function allowDrop(event) {
      event.preventDefault();
    },
    viewDay: function viewDay(_ref3) {
      var date = _ref3.date;
      this.focus = date;
      this.type = "day";
    },
    getEventColor: function getEventColor(event) {
      return event.color;
    },
    prev: function prev() {
      this.$refs.calendar.prev();
    },
    next: function next() {
      this.$refs.calendar.next();
    },
    clearAll: function clearAll() {
      this.visitId = null;
      this.viewVisitDialog = false;
      this.visitTicketDetail = new Object();
      this.visitTicketCustomer = new Object();
      this.visitTicketProperty = new Object();
      this.visitTicketContactPerson = new Object();
    },
    showEvent: function showEvent(_ref4) {
      var nativeEvent = _ref4.nativeEvent,
          event = _ref4.event;

      if (!event.visit) {
        return false;
      }

      this.visitId = this.lodash.toSafeInteger(event.id);
      this.viewVisitDialog = true;
      nativeEvent.stopPropagation();
      /*_this.eventLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "visit/" + visit,
        })
        .then(({ data }) => {
          const {ticket, title, barcode, id, customer, property, property_contact_person} = data;
          _this.visitId = id;
          _this.visitTicketDetail = new Object({
            barcode: barcode,
            attention: ticket.attention,
            title: title,
            ticket_type: ticket.ticket_type,
            id: ticket.id,
          });
          _this.visitTicketCustomer = new Object({customer});
          _this.visitTicketProperty = new Object({
            unit_no: property.unit_no,
            street_1: property.street_1,
            street_2: property.street_2,
            zip_code: property.zip_code,
            latitude: property.latitude,
            longitude: property.longitude,
          });
          _this.visitTicketContactPerson = new Object({
            primary_phone: property_contact_person.primary_phone,
          });
          _this.$nextTick(() => {
            _this.viewVisitDialog = true;
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.eventLoading = false;
        });*/
    }
  }
};