import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import CommonMixin from "@/core/plugins/common-mixin";
import { POST } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import SignaturePad from "signature_pad";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  props: {
    closerDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    detail: {
      required: true,
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    ticketId: {
      type: Number,
      default: 0
    },
    visitId: {
      type: Number,
      default: 0
    },
    timesheetId: {
      type: Number,
      default: 0
    },
    visit: {
      type: Number,
      default: 0
    },
    visitDetail: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    isVisitDetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      pageLoading: false,
      recordingStarted: false,
      recordingStopped: false,
      engineerSignature: null,
      customerSignature: null,
      audioDataURL: null,
      disableVisit: false,
      timeTrackings: [],
      visitList: [],
      data: {
        timesheet: null,
        attachments: [],
        visit: 0,
        details: null
      }
    };
  },
  watch: {
    closerDialog: function closerDialog(param) {
      if (param) {
        this.init();
      }
    }
  },
  methods: {
    updateFiles: function updateFiles(param) {
      this.data.attachments = param;
    },
    recordingStartedUpdate: function recordingStartedUpdate(param) {
      this.recordingStarted = param;
    },
    recordingStoppedUpdate: function recordingStoppedUpdate(param) {
      this.recordingStopped = param;
    },
    updateOrCreate: function updateOrCreate(action) {
      var _this = this;

      if (!_this.$refs.ticketCloserForm.validate()) {
        return false;
      }

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit("update:error", InitializeError("Please stop the audio recorder then try again."));
        return false;
      }

      _this.formLoading = true;
      var signatures = [];
      signatures.push({
        engineer: this.engineerSignature.toDataURL()
      });
      signatures.push({
        customer: this.customerSignature.toDataURL()
      });
      var formData = {
        action: action,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.timesheetId,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        details: _this.data.details,
        collected_amount: _this.data.collected_amount
      };

      _this.$store.dispatch(POST, {
        url: "job/" + _this.ticketId + "/visit/" + _this.visitId + "/closer",
        data: formData
      }).then(function () {
        _this.$emit("create:ticket-closer", true);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    init: function init() {
      var _this = this;

      setTimeout(function () {
        _this.$nextTick(function () {
          var ecanvas = _this.$refs["engineersignature"];
          var eparentDiv = document.getElementById("engineer-signature");
          console.log({
            eparentDiv: eparentDiv
          });

          if (eparentDiv) {
            var eparentWidth = eparentDiv.offsetWidth;
            ecanvas.setAttribute("width", eparentWidth);
            _this.engineerSignature = new SignaturePad(ecanvas);
          }

          var ccanvas = _this.$refs["customersignature"];
          var cparentDiv = document.getElementById("customer-signature");

          if (cparentDiv) {
            var cparentWidth = cparentDiv.offsetWidth;
            ccanvas.setAttribute("width", cparentWidth);
            _this.customerSignature = new SignaturePad(ccanvas);
          }
        });
      }, 500);
    }
  },
  components: {
    Dialog: Dialog
  },
  mounted: function mounted() {
    this.init();
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 60);
    }
  }
};