import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import Priority from "@/view/pages/partials/Priority.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import { PUT, QUERY, POST, PATCH, GET } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { toNumber, isEmpty } from "lodash";
import { downloadEvent } from "@/core/lib/ics.lib";
import DeleteDialog from "@/view/pages/partials/Delete.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import Timepicker from "@/view/pages/partials/Timepicker.vue";
import CreateTimeCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  props: {
    visitDialog: {
      type: Boolean,
      default: false
    },
    isCalendar: {
      type: Boolean,
      default: false
    },
    visit: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        if (param) {
          this.loadContent();
        }
      }
    }
  },
  data: function data() {
    return {
      ticketId: null,
      visitId: null,
      resumeTimerDialog: false,
      assignFormLoading: false,
      assignFormValid: true,
      appointmentUpdateDialog: false,
      timesheetRunningDialog: false,
      apptFormLoading: false,
      allowUpdate: false,
      timerLoading: true,
      timerDialog: false,
      closerDialog: false,
      startTimerDialog: false,
      completeDialog: false,
      updateServiceFormDialog: false,
      assignServiceFormDialog: false,
      allTimerDialog: false,
      timer_remarks: null,
      audioDataURL: null,
      timerFormValid: true,
      timerFormLoading: false,
      recordingStarted: false,
      recordingStopped: false,
      loadingEngineers: false,
      engineerDialog: false,
      apptEngLoading: false,
      runningTimer: new Object(),
      isTimmerRunning: false,
      pageLoading: false,
      detail: new Object(),
      visitDetail: {},
      customer: new Object(),
      contactPerson: new Object(),
      property: new Object(),
      property_contact_person: new Object(),
      billing_contact_person: new Object(),
      billing: new Object(),
      visitNotes: new Object(),
      particuleFieldLoading: false,
      updateDetailDialog: {
        title: false,
        instruction: false
      },
      updateDetail: {
        title: null,
        instruction: null
      },
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_time: null
      },
      serviceFormList: new Array(),
      engineerList: new Array(),
      visitEngineer: null,
      userRunningTimers: new Array(),
      timeInterval: null,
      durationInterval: null,
      timerServiceForm: new Array(),
      timeIntervalLimit: 10000,
      timerDuration: {
        hour: "00",
        minute: "00",
        second: "00"
      },
      closer: {
        pdf_url: null
      },
      assignedTeam: [],
      dbLineItems: [],
      newLineItems: [],
      cancelVisitDialog: false,
      cancelVisitLoading: false,
      cancelVisitBarcode: null,
      lineItemLoading: false
    };
  },
  methods: {
    cancelVisitConfirm: function cancelVisitConfirm() {
      var _this2 = this;

      this.cancelVisitLoading = true;
      this.$store.dispatch(PUT, {
        url: "visit/".concat(this.visitDetail.id, "/mark-as-cancel")
      }).then(function () {
        _this2.loadContent();

        _this2.$emit("reload", true);

        _this2.cancelVisitDialog = false;
      }).catch(function (error) {
        _this2.logError(error);
      }).finally(function () {
        _this2.cancelVisitLoading = false;
      });
    },
    downloadSchedule: function downloadSchedule() {
      var ticketId = this.visitDetail.ticket.id;
      var token = JwtService.getToken();
      var url = "".concat(this.$apiURL, "job/").concat(ticketId, "/download-schedule?token=").concat(token);
      window.open(url, "_blank");
    },
    updateEngineer: function updateEngineer() {
      var visitId = this.visitDetail.id;
      var ticketId = this.visitDetail.ticket.id;

      var _this = this;

      if (!_this.assignedTeam.length) {
        ErrorEventBus.$emit("update:error", InitializeError("Please select an engineer then try again."));
        return false;
      }

      _this.apptEngLoading = true;

      _this.$store.dispatch(PUT, {
        url: "job/" + ticketId + "/visit/" + visitId + "/engineer",
        data: {
          engineer: _this.assignedTeam
        }
      }).then(function () {
        _this.engineerList = [];

        _this.$nextTick(function () {
          _this.loadContent();
        });
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.apptEngLoading = false;
      });
    },
    getAvailableEngineers: function getAvailableEngineers() {
      var _this = this;

      _this.loadingEngineers = true;

      _this.$store.dispatch(QUERY, {
        url: "job/available-engineers",
        data: {
          start_date: moment(_this.visitDetail.started_at).format("YYYY-MM-DD"),
          start_time: moment(_this.visitDetail.started_at).format("HH:mm"),
          end_date: moment(_this.visitDetail.finished_at).format("YYYY-MM-DD"),
          end_time: moment(_this.visitDetail.finished_at).format("HH:mm"),
          force: 0,
          all: 1
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.engineerList = data.engineers; // _this.engineerDialog = true;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.loadingEngineers = false;
      });
    },
    downloadPDF: function downloadPDF() {
      if (this.closer.pdf_url) {
        window.open(this.closer.pdf_url, "_blank");
      } else {
        var token = JwtService.getToken();
        var url = "".concat(this.$apiURL, "visit/").concat(this.visitDetail.id, "/closer/").concat(this.closer.id, "/download?token=").concat(token);
        window.open(url, "_blank");
      }
    },
    routeToInvoice: function routeToInvoice() {
      var ticket = this.visitDetail.ticket;
      this.$router.push(this.getDefaultRoute("invoice.create", {
        query: {
          customer: this.customer.id,
          property: this.property.id,
          billing: this.billing.id,
          visit: this.visitDetail.id,
          ticket: ticket.id
        }
      }));
    },
    closerServiceFormDialog: function closerServiceFormDialog() {
      var _this = this;

      _this.updateServiceFormDialog = false;

      _this.getActiveTimer();

      _this.loadContent();
    },
    assignServiceForm: function assignServiceForm() {
      var _this = this;

      if (!_this.$refs.assignServiceForm.validate()) {
        return false;
      }

      _this.assignFormLoading = true;

      _this.$store.dispatch(POST, {
        url: "job/".concat(_this.detail.id, "/visit/").concat(_this.visit, "/service-form/").concat(_this.timerServiceForm),
        data: {
          timesheet: _this.runningTimer.id || null
        }
      }).then(function () {
        _this.timerServiceForm = new Array();
        ServiceFormEventBus.$emit("reload:service-form");
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.assignFormLoading = false;
        _this.assignServiceFormDialog = false;

        _this.getActiveTimer();
      });
    },
    assignServiceFormDetail: function assignServiceFormDetail() {
      var _this = this;

      _this.timerLoading = true;

      _this.$store.dispatch(QUERY, {
        url: "job/service-forms",
        data: {
          timesheet: _this.runningTimer.id,
          customer: _this.customer.customer
        }
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.serviceFormList = data;
        _this.assignServiceFormDialog = true;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.timerLoading = false;
      });
    },
    createdTimeCloser: function createdTimeCloser() {
      this.closerDialog = false;
      this.$emit("close", true);
      this.$emit("reload", true);
      this.getActiveTimer();
      this.loadContent();
    },
    updatedServiceForm: function updatedServiceForm() {
      this.updateServiceFormDialog = false;
      this.getActiveTimer();
    },
    timesheetRunningClose: function timesheetRunningClose() {
      var _this = this;

      _this.formLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "running-time-sheet"
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;

        _this.getServiceFormList();
      });
    },
    checkRunningTimerStatus: function checkRunningTimerStatus() {
      var _this = this;

      _this.timerLoading = true;

      _this.$store.dispatch(GET, {
        url: "running-time-sheet"
      }).then(function (_ref3) {
        var data = _ref3.data;

        if (_this.lodash.isEmpty(data) === false) {
          _this.userRunningTimers = data;
          _this.timesheetRunningDialog = true;
        } else {
          _this.getServiceFormList();
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.timerLoading = false;
      });
    },
    getServiceFormList: function getServiceFormList() {
      var _this = this;

      _this.timerLoading = true;

      _this.$store.dispatch(QUERY, {
        url: "job/service-forms",
        data: {
          customer: _this.customer.customer
        }
      }).then(function (_ref4) {
        var data = _ref4.data;
        _this.serviceFormList = data;
        _this.startTimerDialog = true;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.timerLoading = false;
        _this.timesheetRunningDialog = false;
      });
    },
    updateAppointmentDate: function updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentStartTime: function updateAppointmentStartTime(param) {
      this.updateAppointmentFormData.start_time = param;
    },
    updateAppointmentEndTime: function updateAppointmentEndTime(param) {
      this.updateAppointmentFormData.end_time = param;
    },
    updateAppointment: function updateAppointment() {
      var _this = this;

      _this.apptFormLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "job/" + _this.detail.id + "/visit/" + _this.visit + "/re-schedule",
        data: {
          date: _this.updateAppointmentFormData.date ? moment(_this.updateAppointmentFormData.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
          start_time: _this.updateAppointmentFormData.start_time ? moment(_this.updateAppointmentFormData.start_time, ["h:mm A"]).format("HH:mm") : moment().startOf("day").format("HH:mm"),
          end_time: _this.updateAppointmentFormData.end_time ? moment(_this.updateAppointmentFormData.end_time, ["h:mm A"]).format("HH:mm") : moment().endOf("day").format("HH:mm")
        }
      }).then(function () {
        _this.appointmentUpdateDialog = false;

        _this.loadContent();

        _this.$emit("reload-calendar", true);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.apptFormLoading = false;
      });
    },
    startTimer: function startTimer() {
      var _this = this;

      if (!_this.$refs.timerStartForm.validate()) {
        return false;
      }

      _this.timerFormLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "job/" + _this.detail.id + "/visit/" + _this.visit + "/timer/start",
        data: {
          detail: _this.timer_remarks,
          service_form: _this.timerServiceForm
        }
      }).then(function () {
        _this.$emit("reload", true);

        _this.startTimerDialog = false;
        _this.timerServiceForm = new Array();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.timerFormLoading = false;

        _this.getActiveTimer();

        _this.loadContent();
      });
    },
    startTimerWithLater: function startTimerWithLater() {
      var _this = this;

      if (_this.isTimmerRunning) {
        _this.closerDialog = true;
      } else {
        _this.timerFormLoading = true;

        _this.$store.dispatch(PATCH, {
          url: "job/" + _this.detail.id + "/visit/" + _this.visit + "/timer/start"
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.$emit("reload", true);

          _this.timerFormLoading = false;

          _this.getActiveTimer(true);

          _this.loadContent();
        });
      }
    },
    setTimerDuration: function setTimerDuration() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.runningTimer) === false) {
        var startDate = _this.lodash.toString(_this.runningTimer.date);

        if (_this.runningTimer.start_at) {
          startDate = _this.lodash.toString(startDate + " " + _this.runningTimer.start_at);
        }

        if (startDate && moment(startDate, ["YYYY-MM-DD HH:mm:ss"]).isValid()) {
          startDate = moment(startDate, ["YYYY-MM-DD HH:mm:ss"]);
          var duration = moment.duration(moment().diff(startDate));
          var hours = parseInt(duration.asHours());
          var minutes = parseInt(duration.asMinutes()) % 60;
          var seconds = parseInt(duration.asSeconds()) % 60;
          _this.timerDuration = {
            hour: hours > 0 ? _this.lodash.padStart(hours, 2, "0") : "00",
            minute: minutes > 0 ? _this.lodash.padStart(minutes, 2, "0") : "00",
            second: seconds > 0 ? _this.lodash.padStart(seconds, 2, "0") : "00"
          };
        }
      }
    },
    updateTimerDuration: function updateTimerDuration() {
      var _this = this;

      clearInterval(_this.durationInterval);
      _this.durationInterval = setInterval(_this.setTimerDuration, 1000);
    },
    recordingStartedUpdate: function recordingStartedUpdate(param) {
      this.recordingStarted = param;
    },
    recordingStoppedUpdate: function recordingStoppedUpdate(param) {
      this.recordingStopped = param;
    },
    stopRunningTimer: function stopRunningTimer() {
      var _this = this;

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit("update:error", InitializeError("Please stop the audio recorder then try again."));
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "job/" + _this.runningTimer.ticket + "/visit/" + _this.runningTimer.visit + "/timer/" + _this.runningTimer.id + "/end",
        data: {
          detail: _this.timer_remarks,
          audio: _this.audioDataURL
        }
      }).then(function () {
        _this.timerDialog = false;
        _this.timer_remarks = null;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;

        _this.getActiveTimer();

        _this.loadContent();
      });
    },
    markAsComplete: function markAsComplete() {
      var _this = this;

      _this.formLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "job/" + _this.detail.id + "/visit/" + _this.visit + "/update-status/1"
      }).then(function () {
        _this.loadContent();

        _this.$emit("reload", true);

        _this.completeDialog = false;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    stopAllTimers: function stopAllTimers() {
      var _this = this;

      _this.formLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "visit/" + _this.visit + "/stop-all-timer"
      }).then(function () {
        _this.loadContent();

        _this.$emit("reload", true);

        _this.allTimerDialog = false;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    checkTimerStatus: function checkTimerStatus() {
      var _this = this;

      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(_this.getActiveTimer, _this.timeIntervalLimit);
    },
    getActiveTimer: function getActiveTimer(closer) {
      var _this = this;

      if (_this.visitDetail.status < 4) {
        _this.timerLoading = true;

        _this.$store.dispatch(GET, {
          url: "visit/" + _this.visit + "/running"
        }).then(function (_ref5) {
          var data = _ref5.data;

          if (data && !isEmpty(data)) {
            _this.runningTimer = new Object(data);
            _this.ticketId = data.ticket;
            _this.visitId = data.visit;
          }

          _this.isTimmerRunning = !!data;

          if (_this.isTimmerRunning) {
            _this.resumeTimerDialog = false;
          }

          if (closer) {
            _this.$nextTick(function () {
              _this.closerDialog = true;
            });
          }
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.timerLoading = false;
        });
      }
    },
    updateField: function updateField(type) {
      var _this = this;

      if (_this.canUpdateVisit) {
        if (!_this.allowUpdate) {
          return false;
        }

        _this.onEscParticularField();

        _this.updateDetailDialog[type] = true;
        _this.updateDetail[type] = _this.visitDetail[type];
        setTimeout(function () {
          _this.$refs[type].focus();
        }, 50);
      }
    },
    onEscParticularField: function onEscParticularField() {
      this.updateDetailDialog = {
        title: false,
        instruction: false
      };
      this.updateDetail = {
        title: null,
        instruction: null
      };
    },
    patchVisit: function patchVisit(data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(PATCH, {
            url: "visit/" + _this.visit,
            data: data
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    updateParticularField: function updateParticularField() {
      var _this = this;

      _this.particuleFieldLoading = true;

      if (_this.canUpdateVisit) {
        _this.patchVisit(_this.updateDetail).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.loadContent();

          _this.particuleFieldLoading = false;

          _this.onEscParticularField();
        });
      }
    },
    resumeTimer: function resumeTimer() {
      var _this = this;

      _this.timerFormLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "visit/" + _this.visit + "/resume"
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.timerFormLoading = false;

        _this.getActiveTimer();

        _this.loadContent();
      });
    },
    downloadVisit: function downloadVisit() {
      try {
        var startDateArray = moment(this.visitDetail.started_at).format("YYYY-MM-DD").split("-").map(function (row) {
          return toNumber(row);
        });
        var startTimeArray = moment(this.visitDetail.started_at).format("HH:mm").split(":").map(function (row) {
          return toNumber(row);
        });
        var endDateArray = moment(this.visitDetail.finished_at).format("YYYY-MM-DD").split("-").map(function (row) {
          return toNumber(row);
        });
        var endTimeArray = moment(this.visitDetail.finished_at).format("HH:mm").split(":").map(function (row) {
          return toNumber(row);
        });

        if (startTimeArray[0] == "00") {
          startTimeArray = new Array();
        }

        var visit = {
          start: this.lodash.concat(startDateArray, startTimeArray),
          end: this.lodash.concat(endDateArray, endTimeArray),
          title: this.visitDetail.title,
          description: this.visitDetail.instruction,
          location: this.property.property_address,
          url: process.env.VUE_APP_BASE_URL,
          geo: {
            lat: toNumber(this.property.latitude),
            lon: toNumber(this.property.longitude)
          },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.detail.added_by.display_name,
            email: this.detail.added_by.user_email
          },
          attendees: this.visitDetail.engineers.map(function (row) {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email
              };
            }
          })
        };
        downloadEvent(visit);
      } catch (error) {
        console.log({
          error: error
        });
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    getVisitScheduleType: function getVisitScheduleType() {
      return this.detail ? this.detail.schedule_message : "";
    },
    loadContent: function loadContent() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(GET, {
        url: "visit/" + _this.visit
      }).then(function (_ref6) {
        var data = _ref6.data;
        _this.visitDetail = data;
        _this.closer = data.closer;
        _this.detail = data.ticket;
        _this.customer = data.customer;
        _this.contactPerson = data.contact_person || new Object();
        _this.property = data.property || new Object();
        _this.property_contact_person = data.property_contact_person || new Object();
        _this.billing_contact_person = data.billing_contact_person || new Object();
        _this.billing = data.billing || new Object();
        _this.visitNotes = data.notes;
        _this.allowUpdate = true;

        if (_this.visitDetail.status === 4) {
          _this.allowUpdate = false;
          clearInterval(_this.timeInterval);
          clearInterval(_this.durationInterval);
        }

        if (_this.visitDetail.engineers && _this.visitDetail.engineers.length) {
          _this.assignedTeam = _this.visitDetail.engineers.map(function (row) {
            return row.engineer;
          });
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.getActiveTimer();

        _this.pageLoading = false;
      });
    }
  },
  components: {
    Dialog: Dialog,
    Priority: Priority,
    Datepicker: Datepicker,
    Timepicker: Timepicker,
    CreateTimeCloser: CreateTimeCloser,
    DeleteDialog: DeleteDialog,
    CustomStatus: CustomStatus
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 70);
    },
    updateStartDate: function updateStartDate() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("YYYY-MM-DD");
      }

      return null;
    },
    updateStartTime: function updateStartTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("hh:mm A");
      }

      return null;
    },
    updateEndTime: function updateEndTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.finished_at).format("hh:mm A");
      }

      return null;
    },
    canUpdateVisit: function canUpdateVisit() {
      if (this.visitDetail && this.visitDetail.status != 1) {
        return false;
      }

      return this.getPermission("visit:update");
    },
    getAppointmentText: function getAppointmentText() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        var date = this.formatDate(this.visitDetail.started_at);
        var startTime = moment(this.visitDetail.started_at).format("hh:mm A");
        var endTime = moment(this.visitDetail.finished_at).format("hh:mm A");
        return date + " " + startTime + " - " + endTime;
      }

      return null;
    },
    getScheduleType: function getScheduleType() {
      if (this.detail) {
        if (this.detail.type === 1) {
          return "One-Off Job";
        }

        if (this.detail.type === 2) {
          return "Contract Job";
        }
      }

      return null;
    }
  }
};